<template>
  <div v-loading.fullscreen.lock="isLoading">
    <el-card shadow="always">
      <div slot="header" class="clearfix">
        <span class="title">查詢條件</span>
      </div>

      <!-- 查詢條件 -->
      <el-form
        ref="form"
        :model="queryData"
        label-width="80px"
        label-position="left"
      >
        
        <el-row :gutter="20">
          <el-col :span="8">
            <el-form-item label="方案名稱">
              <el-input
                v-model="queryData.name"
                placeholder="請輸入方案名稱"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>


        <el-row>
          <el-col :sm="23" class="btn-col">
            <el-button type="primary" @click="query">搜尋</el-button>
            <el-button plain @click="restoreDefault">清除</el-button>
          </el-col>
        </el-row>
      </el-form>
    </el-card>

    <br />

    <el-card>
      <div slot="header" class="clearfix" style="line-height: 40.5px">
        <span class="title">查詢結果</span>
        <el-button style="float: right" type="primary" @click="add"
          >新增</el-button
        >
      </div>

      <el-table
        :data="tableData"
        border
        stripe
        empty-text="暫無數據"
        style="width: 100%"
      >
        <!-- 方案名稱 -->
        <el-table-column
          fixed
          prop="name"
          label="方案名稱"
          min-width="300"
          align="center"
        >
        </el-table-column>

        <!-- 建立者 -->
        <el-table-column
          prop="createdBy"
          label="建立者"
          width="200"
          align="center"
        >
        </el-table-column>

        <!-- 建立日期 -->
        <el-table-column
          prop="dateCreatedStr"
          label="建立日期"
          width="200"
          align="center"
        >
        </el-table-column>

        <!-- 編輯者 -->
        <el-table-column
          prop="lastUpdatedBy"
          label="編輯者"
          width="200"
          align="center"
        ></el-table-column>

        <!-- 編輯日期 -->
        <el-table-column
          prop="lastUpdatedStr"
          label="編輯日期"
          width="200"
          align="center"
        >
        </el-table-column>

        <!-- 操作 -->
        <el-table-column label="操作" width="290" align="center">
          <template slot-scope="scope">
            <el-button
              size="small"
              type="primary"
              v-on:click="edit(scope.row.id)"
            >
              編輯
            </el-button>
       
          </template>
        </el-table-column>
      </el-table>

      <br />

    </el-card>

    
    <!--編輯_方案-->
    <el-dialog
      :visible.sync="dialogUse.editVisible"
      width="70%"
      top="20px"
      :close-on-click-modal="false"
      @closed="closeDialog('edit')"
    >
      <PlanEdit
        :clickTime="dialogUse.clickTimeEdit"
        :id="dialogUse.editId"
        @update-finish="updateFinish"
      />
    </el-dialog>

    <!--新增_方案-->
    <el-dialog
      :visible.sync="dialogUse.addVisible"
      width="70%"
      top="20px"
      :close-on-click-modal="false"
      @closed="closeDialog('add')"
    >
      <PlanAdd
        :clickTime="dialogUse.clickTimeAdd"
        :id="null"
        @add-finish="addFinish"
      />
    </el-dialog>

   
  </div>
</template>

<script>
import API from "../../api"
import PlanEdit from "./PlanEdit"
import PlanAdd from "./PlanAdd"

export default {
  name: "PlanManage",
  components: { PlanEdit, PlanAdd },

  data() {
    return {
      queryData: {
        name: null,
      },
     
      tableData: [],
      
      isLoading: false,
      
      dialogUse: {
        editVisible: false,
        editId: "",
        clickTimeEdit: null,
        addVisible: false,
        addId: "",
        clickTimeAdd: null,
        
      },
    }
  },
 

  created() {
    this.query()
  },

  methods: {
    /**查詢條件 恢復預設值 */
    restoreDefault() {
      this.queryData.name = null
    },

    //方案查詢
    query() {
      this.isLoading = true
      API.plan
        .query(this.queryData)
        .then((res) => {
          this.tableData = res.data.results
          this.isLoading = false
        })
        .catch((e) => {
          console.log(`plan query error`)
          this.$message.error("查詢失敗!")
          this.isLoading = false
        })
    },

    //新增方案
    add() {
      this.dialogUse.addVisible = true
      this.dialogUse.clickTimeAdd = new Date()
    },

    //編輯方案
    edit(id) {
      this.dialogUse.editId = id
      this.dialogUse.editVisible = true
      this.dialogUse.clickTimeEdit = new Date()
    },


    //新增方案完成
    addFinish(newData) {
      this.query()
      this.closeDialog("add")
    },

     //編輯方案完成
      updateFinish(newData) {
       if (newData == null) return
      let i = this.tableData.findIndex((item) => {
        return item.id == newData.id
      })
      if (i >= 0) {
        this.tableData.splice(i, 1, newData)
      }
      this.closeDialog("edit")
    },

    //關閉彈窗(新增/編輯)
    closeDialog(type) {
      switch (type) {
        case "edit":
          this.dialogUse.editVisible = false
          this.dialogUse.editId = null
          break
        case "add":
          this.dialogUse.addVisible = false
          break
  
      }
    },
  },
}
</script>

<style scoped>
.title {
  font-size: 16px;
  font-weight: bold;
}

.el-form .el-select {
  width: 95% !important;
}
.form-range {
  display: inline-block;
}
.form-range.el-select {
  width: 30% !important;
}
.form-range-to {
  margin: 0 10px;
}
.btn-col {
  text-align: right;
}
.btn-col .el-button,
.btn-new .el-button {
  width: 96px;
}
.btn-new {
  float: right;
}


</style>
