<template>
  <div v-loading.fullscreen.lock="isLoading">
    <el-card>
      <div slot="header">
        <span class="title">新增方案</span>
      </div>

      <!--方案名稱-->
      <el-form
        ref="form"
        :rules="rules"
        :model="plan"
        label-width="100px"
        label-position="top"
      >
        <el-form-item label="方案名稱" prop="name" class="fontDetail">
          <el-input
            v-model="plan.name"
            placeholder="請輸入方案名稱"
            style="width: 50%"
            required
          ></el-input>
        </el-form-item>

        <!--月費-->
        <el-form-item label="月費" prop="fee" class="fontDetail">
          <el-input
            v-model="plan.fee"
            placeholder="請輸入月費"
            style="width: 50%"
            required
          >
            <div slot="append">TWD</div>
          </el-input>
        </el-form-item>
        <!--交易手續費-->
        <el-form-item
          label="交易手續費"
          prop="handlingCharge"
          class="fontDetail"
        >
          <el-input
            v-model="plan.handlingCharge"
            placeholder="請輸入手續費"
            style="width: 50%"
            required
          >
            <div slot="append">%</div>
          </el-input>
        </el-form-item>
        <!--帳號數量-->
        <el-form-item label="帳號數量" prop="accountUpLimit" class="fontDetail">
          <el-input
            v-model="plan.accountUpLimit"
            placeholder="請輸入數量"
            style="width: 50%"
          >
            <div slot="append">個</div>
          </el-input>
        </el-form-item>
        <!--商店數量-->
        <el-form-item label="商店數量" prop="storeUpLimit" class="fontDetail">
          <el-input
            v-model="plan.storeUpLimit"
            placeholder="請輸入數量"
            style="width: 50%"
          >
            <div slot="append">個</div>
          </el-input>
        </el-form-item>

        <!--功能管理-->
        <div style="padding-top: 20px">
          <span class="functDetail">功能管理</span>

          <el-row :gutter="15">
            <el-col
              :span="6"
              v-for="(item, index) of allGroups"
              :key="item.id"
            >
              <el-card class="box-card">
                <div slot="header" class="clearfix">
                  <span>{{item.name}}</span>
                </div>
              
              <el-row class="card-box">
                <div v-for="fun in item.functions" :key="fun.id" class="item">
                  {{ fun.name }}
                </div>
               </el-row>
              
                <el-divider></el-divider>
                <span v-if="index==0 || index==1"></span>
                <el-switch
                v-else
                  class="switch"
                  v-model="item.status"
                  :active-value="true"
                  :inactive-value="false"
                  active-text="啟用"
                  inactive-text="停用"
                  active-color="#13ce66"
                >
                </el-switch>
              
              </el-card>
            </el-col>
          </el-row>
        </div>

        <!--新增方案＿底部按鈕-->
        <el-form-item>
          <div class="dialog-footer">
            <el-button type="primary" @click="add">新增</el-button>
          </div>
        </el-form-item>
      </el-form>
    </el-card>
  </div>
</template>

<script>
import API from "../../api"
import { Message } from "element-ui"

export default {
  name: "PlanAdd",
  props: {
    id: String,
    clickTime: Date,
  },

  data() {
    return {
      isLoading: false,
      plan: {
        name: "",
        fee: "",
        handlingCharge: "",
        accountUpLimit: "",
        storeUpLimit: "",
        functionGroups:[]
      },
      allGroups: [
      ],

      rules: {
        name: [
          { required: true, message: "必填", trigger: "blur" },
          { required: true, message: "必填", trigger: "change" },
        ],
        fee: [{ required: true, message: "必填", trigger: "blur" }],
        handlingCharge: [{ required: true, message: "必填", trigger: "blur" }],
      },
    }
  },

  watch: {
    clickTime: function (newVal) {
      this.openWindow()
    },
  },
  created() {
    this.openWindow()
  },
  methods: {
    async openWindow() {
      if (this.$refs["form"]) {
        this.$refs["form"].resetFields() //按新增鍵後, 新增頁面清空
      }

      this.plan.name = ""
      this.plan.fee = ""
      this.plan.handlingCharge = ""
      this.plan.storeUpLimit = ""
      this.plan.accountUpLimit = ""
      this.plan.functionGroups=[];
      const res = await API.functionGroup.getFunctionGroupAll();
      this.allGroups = res.data.map((it,index)=>{
        if(index==0){
          return{...it, status: true}
        } else {
          return{...it, status: false}
        }
       
      });
    }, //新增頁面跳出, 不管是否建立新關係, 頁面都是清空

    //按鈕: 新增
    add() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.plan.functionGroups = this.allGroups.filter(it=>it.status).map(it=>it.id);
          API.plan.addPlan(this.plan).then((newP) => {
            Message({
              message: "新增成功!",
              type: "success",
            })
            this.$emit("add-finish", newP.data)
            this.$refs["form"].resetFields() //設定新增完後, 此新增頁面清除
          })
        }
      })
    },
  },
}
</script>

<style scoped>
/*新增方案title*/
.title {
  font-size: 18px;
  font-weight: bold;
}

/*label*/
.fontDetail {
  margin-left: 35px;
  font-weight: 520;
  font-size: 10px;
  color: #585858;
  margin-bottom: 10px;
}

/*功能管理title*/
.functDetail {
  margin-left: 35px;
  font-weight: bold;
  font-size: 16px;
  color: #585858;
  margin-bottom: 10px;
}

/*頁面最底按鈕位置*/
.dialog-footer {
  text-align: center;
  margin-top: 50px;
}

::v-deep .el-form-item__label {
  margin-bottom: 0px;
  padding: 0px;
}

/*「功能管理」卡片名稱字體*/
.clearfix {
  font-size: 14px;
  font-weight: bold;
}

/*「功能管理」細項上下行距*/
.item {
  margin-bottom: 10px;
  
}

/*「功能管理」卡片寬度與上下距離*/
.box-card {
  height: 280px;
  margin-top: 30px;
}

/*「功能管理」卡片內容高度*/
.card-box {
  height: 120px;
  
}

/*「功能管理」卡片下方分隔線*/
.divide {
  display: flex;
  justify-content: space-between;
  align-items: center;
  
}

/*「功能管理」switch位置*/
.switch {
  padding: 0;
  float: right;
}


</style>
